























































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

import { AuthPasswordChangeFieldError, ErrorManager } from '@/models';

export default Vue.extend({
  name: 'ProfileChangePasswordPage',

  data: () => ({
    showPassword: false,
    new_password1: '',
    new_password2: '',
    errorMessage: '',
    loading: false,

    rules: {
      required: (value: string) => !!value || 'Required.'
    },

    fieldError: new AuthPasswordChangeFieldError()
  }),

  computed: {
    ...mapGetters(['getThemeMode']),
    ...mapGetters({ user: 'auth/getUser' }),

    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    }
  },

  methods: {
    ...mapActions({
      changePassword: 'auth/changePassword',
      logout: 'auth/logout'
    }),

    clearAllErrors(): void {
      this.fieldError.clearErrors();
      this.errorMessage = '';
    },

    submitForm() {
      this.clearAllErrors();

      this.loading = true;

      this.changePassword({
        new_password1: this.new_password1,
        new_password2: this.new_password2
      })
        .then(() => {
          this.logout();
        })
        .catch((error) => {
          if (error.response) {
            // client received an error response that falls out of range 2xx
            if (
              AuthPasswordChangeFieldError.isPasswordChangeFieldError(
                error.response.data
              )
            ) {
              this.fieldError = new AuthPasswordChangeFieldError(
                error.response.data
              );
            }
          }
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => (this.loading = false));
    }
  }
});
